/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
// App.tsx
import React, { Component } from 'react';
import { Button, Spinner, Stack } from 'react-bootstrap';

import 'seatchart/dist/seatchart.min.css';
import './App.css';
import AppAlert from './components/AppAlert';
import ScreenContainer from './components/ScreenContainer';
import SeatPlanContainer from './components/SeatContainer';

class App extends Component {
  constructor(props) {
    super(props);

    // Get URL params
    const urlParams = new URLSearchParams(window.location.search);
    const basketId = urlParams.get('basketId') || null;
    const siteId = urlParams.get('siteId') || null;
    const eventDateId = urlParams.get('eventDateId') || null;
    const authHash = urlParams.get('hash') || null;
    const windowMode = urlParams.get('mode') || null;
    const userId = urlParams.get('userId') || null;

    // Set default state
    this.state = {
      error: false,
      authHash,
      userId,
      basketId,
      siteId,
      eventDateId,
      site: null,
      isLoading: false,
      hasFetchedSite: false,
      event: null,
      seatingPlan: null,
      hasFetchedEvent: false,
      seatingProducts: null,
      windowMode,
      isSelectingProduct: false,
      selectingProduct: null,
    };

    // Iniital check for site
    if ((this.state?.basketId || this.state?.userId) && this.state?.siteId
        && !this.state.hasFetchedSite) {
      this.getSite();
    }
  }

  doAlert(message) {
    if (this.state.windowMode === 'APP') {
      window.ReactNativeWebView.postMessage({ alert: message });
    } else {
      alert(message);
    }
  }

  // Get the site info from Pluto
  async getSite() {
    fetch(`${process.env.REACT_APP_PLUTO_URL}/sites/${this.state.siteId}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        this.setState({ isLoading: false, error: true });
      })
      .then((result) => {
        // Set state and move onto event
        this.setState({ site: result }, () => {
          // Set the title
          if (this.state.site.name !== undefined) {
            document.title += ` | ${this.state.site.name}`;
          }

          // Set site primary colour
          if (this.state.site.primary_colour !== undefined) {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `.sc-cart-btn-submit, .sc-cart-btn-delete { background-color: ${this.state.site.primary_colour} !important; }`;
            document.getElementsByTagName('head')[0].appendChild(style);
          }

          if (this.state.windowMode !== 'MANAGE') {
            // Get the event
            this.getEvent();
          } else {
            this.getManagementEvent();
          }
        });
      });
  }

  // Get event and seating plan
  async getEvent() {
    this.setState({
      isLoading: true,
    }, () => {
      fetch(`${process.env.REACT_APP_PLUTO_URL}/events/dates/${this.state.eventDateId}/seating`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Site-Id': this.state.site.id,
          'X-Basket-Id': this.state.basketId,
          'X-Seating-Auth': this.state.authHash,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          this.setState({ isLoading: false, error: true });
        })
        .then((result) => {
          // Set state and move onto event
          this.setState({
            event: result,
            seatingPlan: JSON.parse(result.plan),
            seatingProducts: result.products,
            isLoading: false,
            hasFetchedEvent: true,
          });
        });
    });
  }

  // Get management event
  async getManagementEvent() {
    this.setState({
      isLoading: true,
    }, () => {
      fetch(`${process.env.REACT_APP_PLUTO_URL}/events/dates/${this.state.eventDateId}/seating-manage`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Site-Id': this.state.site.id,
          'X-User-Id': this.state.userId,
          'X-Seating-Auth': this.state.authHash,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          this.setState({ isLoading: false, error: true });
        })
        .then((result) => {
          // Set state and move onto event
          this.setState({
            event: result,
            seatingPlan: JSON.parse(result.plan),
            seatingProducts: result.products,
            isLoading: false,
            hasFetchedEvent: true,
          });
        });
    });
  }

  // Render
  render() {
    // Reserve tickets
    const reserveTickets = (tickets) => {
      // Set is loading and then process the ticket submission
      this.setState({
        isLoading: true,
      }, () => {
        let reserveUrl = null;
        let headers = null;

        if (this.state.windowMode !== 'MANAGE') {
          headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Site-Id': this.state.site.id,
            'X-Basket-Id': this.state.basketId,
            'X-Seating-Auth': this.state.authHash,
          };

          reserveUrl = `${process.env.REACT_APP_PLUTO_URL}/events/dates/${this.state.eventDateId}/seating`;
        } else {
          headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Site-Id': this.state.site.id,
            'X-User-Id': this.state.userId,
            'X-Seating-Auth': this.state.authHash,
          };

          reserveUrl = `${process.env.REACT_APP_PLUTO_URL}/events/dates/${this.state.eventDateId}/seating-manage`;
        }

        fetch(reserveUrl, {
          method: 'POST',
          body: JSON.stringify({ seats: tickets.cart }),
          headers: new Headers(headers),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }

            this.setState({ isLoading: false });

            this.doAlert('The chosen seats are currently not available - please make another selection.');
          })
          .then((result) => {
            this.setState({
              isLoading: false,
            });

            if (!result.success) {
              this.doAlert('The chosen seats are currently not available - please make another selection.');
            } else if (this.state.windowMode === 'WEB') {
              parent.postMessage('SEAT_DONE', '*');
            } else if (this.state.windowMode === 'APP') {
              window.ReactNativeWebView.postMessage('{"success": true}');
            } else if (this.state.windowMode === 'MANAGE') {
              this.doAlert('The seats have now been reserved.');
              this.getManagementEvent();
            }
          });
      });
    };

    const setSelectingProduct = (type, id) => {
      this.setState({
        isSelectingProduct: true,
        selectingProduct: { id, type },
      });
    };

    const assignToProduct = (type, id, productId) => {
      this.setState({
        isLoading: true,
      }, () => {
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Site-Id': this.state.site.id,
          'X-User-Id': this.state.userId,
          'X-Seating-Auth': this.state.authHash,
        };

        const productUrl = `${process.env.REACT_APP_PLUTO_URL}/events/dates/${this.state.eventDateId}/seating-manage/products`;

        fetch(productUrl, {
          method: 'POST',
          body: JSON.stringify({ type, id, product_id: productId }),
          headers: new Headers(headers),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }

            this.setState({ isLoading: false, isSelectingProduct: false });

            this.doAlert('There was an error reserving the seat selection.');
          })
          .then((result) => {
            this.setState({
              isLoading: false,
              isSelectingProduct: false,
            });

            if (!result.success) {
              this.doAlert('There was an error reserving the seat selection.');
            } else {
              this.getManagementEvent();
            }
          });
      });
    };

    const cancelProductAssign = () => {
      this.setState({
        isSelectingProduct: false,
        selectingProduct: null,
      });
    };

    const textColor = {
      color: this.state?.site?.primary_colour ?? '#1ab394',
    };

    // Unknown error occurred
    if (this.state.error === true) {
      return (
        <ScreenContainer logo={this.state.site.logo_url ?? null} title="Oops...">
          <AppAlert site={this.state.site} title="Unknown error" error="An error occurred. If this keeps happening, contact your Union for help" />
        </ScreenContainer>
      );
    }

    // No site set
    if (this.state.site === null) {
      return (
        <div className="complete-center d-flex flex-column align-items-center justify-content-center">
          <Spinner className="bg-light" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }

    // No bearer token
    if (this.state.basketId === null && this.state.userId === null) {
      return (
        <ScreenContainer logo={this.state.site.logo_url ?? null} title="Oops...">
          <AppAlert site={this.state.site} title="Unauthorised Request" error="The request was unauthorised." />
        </ScreenContainer>
      );
    }

    // Is Loading
    if (this.state.isLoading) {
      return (
        <ScreenContainer logo={this.state.site.logo_url ?? null}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Spinner style={textColor} animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </ScreenContainer>
      );
    }

    if (this.state.isSelectingProduct) {
      return (
        <ScreenContainer logo={this.state.site.logo_url ?? null} title="Select Product">
          <Stack gap={3}>
            {this.state.event.products.map((value) => (
              <Button
                variant="light"
                onClick={() => { assignToProduct(this.state.selectingProduct.type, this.state.selectingProduct.id, value.id); }}
              >
                {value.name}
              </Button>
            ))}
            <Button
              variant="secondary"
              onClick={() => {
                cancelProductAssign();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </ScreenContainer>
      );
    }

    // Has site and event by this point
    if (this.state.hasFetchedEvent) {
    // Has site and event by this point
      return (
        <ScreenContainer title={this.state.event.name} logo={this.state.site.logo_url}>
          <SeatPlanContainer
            options={this.state.seatingPlan}
            reservedSeats={this.state.event.reserved_seats}
            products={this.state.seatingProducts}
            reserveTickets={reserveTickets}
            windowMode={this.state.windowMode}
            selectingProduct={setSelectingProduct}
          />
        </ScreenContainer>
      );
    }

    return (<>Index</>);
  }
}

export default App;
