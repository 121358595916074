/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

function CustomContainer(props) {
  return (
    <div {...props}>
      <div className="card border-0">
        <div className="card-body">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default CustomContainer;
