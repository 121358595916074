/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
// Seatchart.tsx
import React, { forwardRef, useEffect, useRef } from 'react';
import SeatchartJS, { Options } from 'seatchart';

function setForwardedRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

const Seatchart = forwardRef(({ options }, ref) => {
  const seatchartRef = useRef();
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current && !seatchartRef.current) {
      seatchartRef.current = new SeatchartJS(elementRef.current, options);
      setForwardedRef(ref, seatchartRef.current);

      return () => {
        seatchartRef.current = undefined;
        setForwardedRef(ref, undefined);
      };
    }
  }, []);

  return (
    <div ref={elementRef} />
  );
});

export default Seatchart;
