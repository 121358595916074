/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef } from 'react';
import Seatchart from '../Seatchart';

function SeatPlanContainer(props) {
  const seatchartRef = useRef();

  useEffect(() => {
    // On submit
    seatchartRef.current?.addEventListener('submit', (event) => {
      props.reserveTickets(event);
    });

    // Hide default seat
    const elementList = document.querySelectorAll('div[class="sc-legend-bullet seat-default"]');

    if (elementList[0] !== undefined) {
      elementList[0].parentElement.remove();
    }

    // Make column clickable
    if (props.windowMode === 'MANAGE') {
      const indexColumnCollection = document.querySelectorAll('div[class="sc-indexer sc-indexer-columns"] > div[class="sc-seat-indexer"]');
      const indexColumns = Array.from(indexColumnCollection);

      indexColumns.forEach((value, index) => {
        indexColumns[index].addEventListener('click', () => {
          props.selectingProduct('C', value.innerHTML);
        });
      });

      // Make rows clickable
      const seatCollection = document.querySelectorAll('div[class="sc-seat sc-seat-reserved seat-default"]');
      const seatIndividual = Array.from(seatCollection);

      seatIndividual.forEach((value, index) => {
        seatIndividual[index].addEventListener('click', () => {
          props.selectingProduct('S', value.innerHTML);
        });
      });
    }
  }, [seatchartRef]);

  // Reserved seats
  const { reservedSeats } = props;

  let validSeatRows = [];
  let validSeatColumns = [];
  const validSeats = [];

  // Seat type array
  const seatTypes = {
    default: {
      cssClass: 'seat-default',
      price: 0,
      label: 'Not on Sale',
    },
  };

  props.products.map((productValue) => {
    const seatConfig = JSON.parse(productValue.seat_configuration);

    seatTypes[`product_${productValue.id}`] = {
      label: productValue.name,
      cssClass: seatConfig.css !== undefined ? `seat-${seatConfig.css}` : 'seat-standard',
      price: parseFloat(productValue.price),
      seatColumns: seatConfig.seatColumns !== undefined ? seatConfig.seatColumns : [],
      seatRows: seatConfig.seatRows !== undefined ? seatConfig.seatRows : [],
      seats: seatConfig.seats !== undefined ? seatConfig.seats : [],
    };

    if (seatConfig.seatColumns !== undefined) {
      validSeatColumns = validSeatColumns.concat(seatConfig.seatColumns);
    }

    if (seatConfig.seatRows !== undefined) {
      validSeatRows = validSeatRows.concat(seatConfig.seatRows);
    }

    if (seatConfig.seats !== undefined) {
      seatConfig.seats.forEach((seat) => {
        validSeats.push({ row: seat.row, col: seat.col });
      });
    }
  });

  // Go through each seat and disable if not a valid product on sale (not manage)
  for (let row = 0; row < props.options.rows; row++) {
    for (let col = 0; col < props.options.columns; col++) {
      // Hide the row as there is no product on sale
      let showSeat = false;

      // Show seat
      if (validSeatRows.includes(row) || validSeatColumns.includes(col)
      || validSeats.filter((seat) => seat.row === row && seat.col === col).length > 0) {
        showSeat = true;
      }

      if (!showSeat) {
        reservedSeats.push({ row, col });
      }
    }
  }

  const seatPlanOptions = {
    cart: {
      currency: '£',
      submitLabel: 'Submit',
      visible: true,
    },
    map: {
      columnSpacers: props.options.columnSpacers !== undefined ? props.options.columnSpacers : [],
      rowSpacers: props.options.rowSpacers !== undefined ? props.options.rowSpacers : [],
      disabledSeats: props.options.disabledSeats !== undefined ? props.options.disabledSeats : [],
      reservedSeats,
      rows: props.options.rows,
      columns: props.options.columns,
      seatTypes,
    },
  };

  return (
    <Seatchart ref={seatchartRef} options={seatPlanOptions} />
  );
}

export default SeatPlanContainer;
