/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import CustomContainer from './CustomContainer';

function AppAlert(props) {
  const styles = {
    text: {
      color: props.site.primary_colour ?? '#1ab394',
    },
    button: {
      backgroundColor: props.site.primary_colour ?? '#1ab394',
      borderColor: props.site.primary_colour ?? '#1ab394',
    },
  };

  return (
    <div className="mt-2">
      <CustomContainer>
        <h4 className="card-title mb-4" style={styles.text}>{props.title}</h4>
        <h6 className="card-text mb-3">{props.error}</h6>
        <p className="card-text">{props.helpText ?? ''}</p>
        {props.children}
      </CustomContainer>
    </div>
  );
}

export default AppAlert;
