/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Container from 'react-bootstrap/Container';

function ScreenContainer(props) {
  return (
    <div>
      <Container className={`d-flex ${props.noMargin ? 'mt-1 ' : 'mt-4 '} flex-column${props.containerStyling ? ' card border-0 card-body' : ''}`}>
        {props.title !== '' && <h3 className="mb-2">{props.title}</h3>}
        {props.children}
      </Container>
    </div>
  );
}

export default ScreenContainer;
